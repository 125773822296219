import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header-container">
      <div className="header-holder">
        <a href="https://alwaysindemand.com/home/">
          <img
            src="https://alwaysindemand.com/wp-content/themes/egiadefault2019/images/AIDLetter.png"
            alt="Logo"
            className="logo"
          />
        </a>
        <nav className={`navigation ${isOpen ? 'open' : ''}`}>
          <a href="https://alwaysindemand.com/why-hvac/" className="nav-link">
            WHY HVAC?
          </a>
          <a href="https://alwaysindemand.com/scholarship/" className="nav-link">
            SCHOLARSHIPS
          </a>
          <a href="https://alwaysindemand.com/resources/" className="nav-link">
            RESOURCES
          </a>
        </nav>
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className={`menu-bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`menu-bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`menu-bar ${isOpen ? 'open' : ''}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Header;