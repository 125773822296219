import React from "react";
import "./Card.css";

const Card = ({
  school,
  contact,
  address,
  city,
  state,
  zip,
  phone,
  email,
  website,
}) => {
  return (
    <div className="card">
      <img
        src="https://alwaysindemand.com/wp-content/uploads/2024/02/directory-orange-bar.jpg"
        alt="card header background"
        className="card-image"
      />
      <div className="card-header">{school}</div>
      <div className="card-divider"></div>
      <div className="card-body">
        <p className="card-contact">
          <strong>Main Contact:</strong>
          <br />
          {contact}
        </p>
        <p className="card-address">
          <strong>Address:</strong>
          <br /> {address}
          <br />
          {city}, {state} {zip}
        </p>
        <p className="card-phone">
          <strong>Contact Phone:</strong>
          <br />
          {phone}
        </p>
        <p className="card-email">
          <strong>Contact Email:</strong>
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </p>

        <a
          href={website}
          target="_blank"
          className="card-website"
          rel="noreferrer"
        >
          <button>View School Website</button>
        </a>
      </div>
    </div>
  );
};

export default Card;
