import React from 'react';
import ResetFilterButton from '../ResetFilterButton/ResetFilterButton';
import './Sidebar.css'

const allStates = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA",
  "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK",
  "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

function Sidebar({ selectedState, onSelectState, onResetFilters }) {
  return (
    <div className="sidebar">
    <h2>Filter by State</h2>
    <select value={selectedState} onChange={(e) => onSelectState(e.target.value)}>
      <option value="">All States</option>
      {allStates.map((state) => (
        <option key={state} value={state}>
          {state}
        </option>
      ))}
    </select>
    <ResetFilterButton onReset={onResetFilters} />
  </div>
  );
}

export default Sidebar;