import React from 'react';
import Card from '../Card/Card';
import './FilteredData.css';

const FilteredData = ({ data }) => {
  if (data.length === 0) {
    return (
      <div className='no-results'>
        <p>This state does not have any trade schools listed at this time.</p>
      </div>
    );
  }

  return (
    <div className="filtered-data">
      {data.map((item, index) => (
        <Card
          key={index}
          school={item.school}
          contact={item.contact}
          address={item.address}
          city={item.city}
          state={item.state}
          zip={item.zip}
          phone={item.phone}
          email={item.email}
          website={item.website}
        />
      ))}
    </div>
  );
};

export default FilteredData;
