// import React, { useState } from 'react';
// import './App.css';
// import FilteredData from './components/FilteredData/FilteredData.js';
// import Sidebar from './components/Sidebar/Sidebar.js';
// import data from './data/egia_accredited_programs.json';

// function App() {
//   const [selectedState, setSelectedState] = useState(''); // Initialize selectedState as empty
//   const states = [...new Set(data.map((item) => item.state))]; // Get unique states from data

//   const filteredData = data.filter((item) => selectedState === '' || item.state === selectedState);

//   return (
//     <div className="App">
//       <h1>JSON Data Search</h1>
//       <div className="content">
//         <Sidebar
//           states={states}
//           selectedState={selectedState}
//           onSelectState={setSelectedState}
//         />
//         <FilteredData filteredData={filteredData} />
//       </div>
//     </div>
//   );
// }

// export default App;


import React, { useState, useEffect } from 'react';
import PageContainer from './components/PageContainer/PageContainer';
import FilteredData from './components/FilteredData/FilteredData';
import Header from './components/Header/Header';
import data from './data/egia_accredited_programs.json';
import Sidebar from './components/Sidebar/Sidebar.js';

const App = () => {
  const [selectedState, setSelectedState] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (selectedState === '') {
      setFilteredData(data);
    } else {
      const filtered = data.filter(item => item.state === selectedState);
      setFilteredData(filtered);
    }
  }, [selectedState]);

  const handleResetFilters = () => {
    setSelectedState('');
  };

  return (
    <div>
      <Header text="Welcome to Our School Directory" />
      <PageContainer>
      <Sidebar
          selectedState={selectedState}
          onSelectState={setSelectedState}
          onResetFilters={handleResetFilters}
        />
        <FilteredData data={filteredData} />
      </PageContainer>
    </div>
  );
};

export default App;
