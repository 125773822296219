import React from 'react';
import './ResetFilterButton.css';

const ResetFilterButton = ({ onReset }) => {
  return (
    <button className="reset-filter-button" onClick={onReset}>
      Reset Filters
    </button>
  );
};

export default ResetFilterButton;